/* Add here all your CSS customizations */

@p4r-blue: #00b7e5;

html {
    overflow-y: initial !important;
    overflow-x: initial !important;
}

body {

    .btn-primary {
        background-color: @p4r-blue;
        border-color: @p4r-blue;
    }

    .disabled {
        pointer-events: all !important;
        cursor: not-allowed;
    }

    .auto-height {
        height: auto;
    }

    .popover {
        z-index: 999999;
    }

    .body-sign {
        .panel-sign {
            .panel-body {
                border-top-color: @p4r-blue;
            }

            .panel-title-sign {
                .title {
                    background-color: @p4r-blue;
                }
            }
        }
    }

    .panel-action {
        padding-left: 10px;

        i {
            padding-right: 5px;
        }
    }

    .form-bordered {
        .form-group:last-child {
            padding-bottom: 7px !important;
        }
    }

    .obsolete {
        text-decoration: line-through;
    }

    .date-picker {
        width: 80%;
        display: inline-block;
    }

    .row {
        &.action-buttons {
            footer.panel-footer {
                border-radius: 5px;
                margin-top: -20px;
                border-top: none;
            }
        }
    }

    .tab-pane {
        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .panel {
            margin-bottom: 0;
        }
    }

    .tab-pane .select2-container {
        width: 100% !important;
    }

    .large-textarea {
        height: 100px;
    }

    .widget-summary {
        display: inline-block;
        height: 95px;
        overflow: hidden;
    }

    .search-content {
        background-color: #fff;

        .search-toolbar {
            background-color: #fff;
        }

        .result-thumb {
            width: 150px;

            i {
                font-size: 4.2rem;
            }
        }
    }

    .panel {
        .select2-container {
            width: 100% !important;
        }
    }

    .alert {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .table {

        .column-id {
            width: 70px;
        }

        .column-date {
            width: 150px;
        }

        .column-enum {
            width: 150px;
        }

        .column-action {
            width: 150px;
            text-align: center;

            i {
                padding-right: 3px;
            }
        }
            
        .column-action-edit {
            width: 122px;
        }

        .column-action-sequence {
            width: 40px;
        }
    }

    .dataTables_wrapper .dataTables_empty {
        padding: 25px;
    }

    .form-data-profile-editor {
        .well {
            padding-bottom: 16px;
        }

        .criteria-view {
            &.well {
                background: inherit;
                border-color: #eff2f7;
            }
        }

        .visible {
            .form-group {
                &.actions {
                    padding-bottom: 0 !important;
                }
            }
        }

        .visible:last-of-type {
            margin-bottom: 0;
        }
    }

    .panel-body {
        &.first {
            border-radius: 0 0 5px 5px;
        }
    }

    .page-header .breadcrumbs {
        margin-right: 20px;
    }

    .validation-summary {

        .panel-heading {
            padding-top: 14px;
            padding-bottom: 14px;
        }

        .panel-actions {
            top: 10px;
        }

        .panel-title {
            font-size: 18px;
            line-height: 18px;
        }

        .panel-body {
            border-top: 0 !important;
            padding: 15px 15px 0 !important;
        }

        .validation-message {
            ul {
                border-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    .form-builder {
        padding: 0;
        min-width: 500px;
        position: relative;

        h3, h4 {
            margin: 0;
        }

        .fa {
            width: 12px;
            text-align: center;
            margin-right: 2px;
        }

        .toolbox {
            width: 200px;
            position: absolute;
            padding-right: 10px;
            z-index: 1;

            .dd-list {
                padding: 0;
            }

            .heading {
                cursor: pointer;
            }
        }

        &.fixed-toolbox .toolbox {
            position: fixed;
        }

        .toolbox-item {
            width: 190px;
            cursor: pointer;
        }

        .definition {
            margin-left: 200px;
            padding-left: 10px;
            border-left: 1px solid #eff2f7;
        }

        .dd-handle.heading {
            background: #00b7e5 !important;
            border-color: #00b7e5 !important;
            color: #FFF !important;
        }

        .dd-handle.subheading {
            background: #999 !important;
            border-color: #999 !important;
            color: #FFF !important;
            cursor: initial !important;
        }

        .dd-handle.action {
            cursor: pointer;
        }

        .definition-page {
            .dd-handle.heading {
                &:hover {
                    background: #0088cc !important;
                    border-color: #0088cc !important;
                }
            }
        }

        .definition-element {
            .dd-handle {
                margin-right: 28px;
                cursor: pointer;
                -webkit-transition: transform 0.3s;
                transition: transform 0.3s;

                span {
                    display: inline-block;
                    vertical-align: top;
                    width: ~"calc(100% - 20px)";
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &.pulse {
                    transform: scale(1.03);
                }

                &.pulsing {
                    .linked-element {
                        display: none;
                    }
                }
            }

            .edit-element {
                position: absolute;
                right: 0;
                font-size: 18px;
                line-height: 32px;
                color: #333 !important;
                width: 28px;
                text-align: center;
            }

            .linked-element,
            .mandatory-element {
                position: absolute;
                right: 28px;
                top: 0;
                height: 34px;
                line-height: 34px;
                width: 40px;
                text-align: center;
                font-size: 1.6rem;
                color: #333;
                width: 35px !important;

                .fa {
                    margin-right: 0;
                }
            }

            .heading .linked-element {
                color: #FFF;
            }

            &.obsolete {
                font-style: italic;
                text-decoration: line-through;

                .dd-handle {
                    color: #999;

                    &.heading {
                        background: #a1d8e5 !important;
                        border-color: #a1d8e5 !important;
                    }
                }
            }
        }

        .dd ol.dd-list.empty:after,
        .drag-target ol.dd-list:not(.empty) > .definition-element:last-child:after {
            margin-top: 5px;
            display: block;
            width: ~"calc(100% - 28px)";
            height: 34px;
            padding: 6px 10px;
            background: #EEE;
            border: 1px dashed #CCC;
            content: '';
        }

        .dd ol.dd-list.empty {
            &.element-list-page:after {
                content: 'No Pages';
            }

            &.element-list-question:after {
                content: 'No Questions';
            }

            &.element-list-option:after {
                content: 'No Options';
            }
        }
    }

    .form-builder-dialog-auto-options {
        .option-set {
            height: 200px;

            textarea {
                resize: none;
                height: 100%;
            }
        }

        .option-list {
            overflow-y: scroll;
            border: 1px solid #CCC;
            border-radius: 4px;
            height: 100%;
            padding: 5px 10px;
        }

        .dd-handle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.table {
    &.search-help {
        margin: 0 0 5px 0;
        text-align: right;
    }
}

.spinner-unit {
    vertical-align: top;
    padding: 7px 0 0 10px;
    display: inline-block;
}